<template>
  <v-card class="rounded-lg" flat>
    <div class="d-flex align-center px-4 py-4">
      <div
        class="key-number font-weight-bold rounded-circle cyan lighten-5 rounded tp-border-thin pa-3 mr-3"
      >
        {{ keywordIndex + 1 }}
      </div>
      <div class="mr-4">
        <v-text-field
          v-model="keyword.search"
          class="text-body-1"
          dense
          flat
          hide-details
          outlined
          placeholder="Nhập từ khóa"
          solo
        >
          <template #prepend-inner>
            <v-icon class="mr-1" size="18px">mdi-key-outline</v-icon>
          </template>
        </v-text-field>
      </div>
      <div class="flex-grow-1">
        <v-text-field
          v-model="keyword.url"
          class="text-body-1"
          dense
          flat
          hide-details
          outlined
          placeholder="Đường dẫn"
          solo
        >
          <template #prepend-inner>
            <v-icon class="mr1" size="18px">mdi-link-variant</v-icon>
          </template>
        </v-text-field>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    keyword: {
      type: Object
    },
    keywordIndex: {
      type: Number
    }
  }
};
</script>

<style lang="scss" scoped>
.key-number {
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  height: 36px;
  margin-right: 0.5rem;
  min-width: 36px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 36px;
}
</style>
