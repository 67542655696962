<template>
  <div>
    <!-- Start: Top -->
    <div class="d-flex align-center">
      <div class="text-h5 font-weight-bold">Từ khóa tìm kiếm</div>
      <v-spacer></v-spacer>
      <v-btn
        class="rounded-lg"
        color="primary"
        depressed
        @click="updateSearchKeys"
      >
        Lưu
      </v-btn>
    </div>
    <!-- End: Top -->
    <!-- Start: List keywords -->
    <v-row class="mt-2">
      <v-col
        v-for="(item, index) in searchKeywords"
        :key="index"
        cols="12"
        md="6"
      >
        <card-search-keyword
          :keyword="item"
          :keyword-index="index"
        ></card-search-keyword>
      </v-col>
    </v-row>
    <!-- Start: List keywords -->
  </div>
</template>

<script>
import CardSearchKeyword from "./components/CardSearchKeyword";

export default {
  components: {
    CardSearchKeyword
  },
  computed: {
    searchKeywords() {
      return this.$store.getters["SEARCH_KEYWORD/searchKeywords"];
    },
    searchKeywordStatusRequest() {
      return this.$store.getters["SEARCH_KEYWORD/statusRequest"];
    }
  },
  async created() {
    await this.$store.dispatch("SEARCH_KEYWORD/getSearchKeywords");
  },
  methods: {
    async updateSearchKeys() {
      await this.$store.dispatch("SEARCH_KEYWORD/updateSearchKeywords", {
        listSearches: this.searchKeywords
      });
      // Alert
      if (
        this.searchKeywordStatusRequest.value === "success-updateSearchKeywords"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã lưu cập nhật"
          }
        });
      }
    }
  }
};
</script>
